// Privacy.js
import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="App">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full mx-auto">
        <h1 className="text-2xl font-bold text-center text-blue-600 mb-6">Privacy Disclaimer</h1>
        <p className="text-gray-700">
          Your BlueSky credentials (username and password) are used directly from your device
          with the server to make API calls to BlueSky to post on your behalf. These credentials are never stored or shared with
          any external services.
        </p>
        <Link to="/" className="text-blue-500 hover:underline mt-4 block text-center">
          Back to App
        </Link>
      </div>
    </div>
  );
};

export default Privacy;
