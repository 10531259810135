import React from "react";
import { Link } from "react-router-dom";

const TermsCondition = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center text-blue-600 mb-4">
          Terms and Conditions for BlueSky A.I. Post App
        </h1>
        <p className="text-center text-gray-700 mb-6">Effective Date: 24/03/2025</p>
        <ol className="list-decimal ml-6 text-gray-800 space-y-4">
          <li>
            <strong>Acceptance of Terms:</strong> By accessing or using the BlueSky A.I. Post App (the “Application”), you agree to be bound by these Terms and Conditions (“Terms”). If you do not agree with any part of these Terms, you must refrain from using the Application.
          </li>
          <li>
            <strong>User Responsibility for Content:</strong> By using this Application, you acknowledge and agree that any content posted to your BlueSky account via the Application is solely your responsibility. The Application acts solely as a facilitator for posting content to BlueSky, and as such, you are entirely liable for all content you publish. This includes ensuring that your posts comply with BlueSky’s terms, community guidelines, and all applicable laws.
          </li>
          <li>
            <strong>Account Security and Credentials:</strong> You are responsible for maintaining the confidentiality of your BlueSky account credentials. The Application uses your credentials to interact with the BlueSky API directly from your device. We do not store or share your credentials with any external services. If you suspect any unauthorized use of your account, you must change your password immediately.
          </li>
          <li>
            <strong>Disclaimer of Warranties:</strong> The Application is provided on an “as is” basis without warranties of any kind—whether express or implied. We do not guarantee that the Application will meet your requirements or that its operation will be uninterrupted or error-free. Your use of the Application is at your own risk.
          </li>
          <li>
            <strong>Limitation of Liability:</strong> Under no circumstances shall BlueSky or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the Application, including but not limited to any issues arising from content posted to your BlueSky account.
          </li>
          <li>
            <strong>Modifications to These Terms:</strong> We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting. Your continued use of the Application after any modifications constitutes your acceptance of the updated Terms.
          </li>
        </ol>
        <div className="mt-6 text-center">
          <p>
            By using the BlueSky A.I. Post App, you confirm that you have read, understood, and agree to these Terms and Conditions, including the clause that holds you solely responsible for any content posted to your BlueSky account.
          </p>
        </div>
        <div className="mt-6 text-center">
          <Link to="/" className="text-blue-500 hover:underline">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
