import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Register the service worker for production
//serviceWorkerRegistration.register();

/*
// ✅ Service Worker Registration
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`bluesky/sw.js`)
      .then((registration) => {
      //  console.log('✅ Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('❌ Service Worker registration failed:', error);
      });
  });
}
*/

// ✅ Render the React Application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ✅ Optional: Measure performance (e.g., reportWebVitals(console.log))
reportWebVitals();
