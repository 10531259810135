export const registerFirebaseServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/bluesky/firebase-messaging-sw.js')
    .then(registration => {
      console.log("✅ Service Worker Registered:", registration);
    })
    .catch(error => {
      console.error("🔥 Service Worker Registration Failed:", error);
    });
}

 else {
    console.warn("🚫 Service workers are not supported in this browser.");
  }
};
