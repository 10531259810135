import React from 'react';

function Error() {
  return (
    <div className="error-container">
      <h2>Error</h2>
      <p>Something went wrong. Please check the URL or try again later.</p>
    </div>
  );
}

export default Error;
