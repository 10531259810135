// Privacy.js
import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="App">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full mx-auto">
        <h1 className="text-2xl font-bold text-center text-blue-600 mb-6">Prompt | Profanity</h1>
        <p className="text-gray-700">
        A profanity filter is implemented to ensure that content posted through the BlueSky application remains respectful, appropriate, and in compliance with platform guidelines. Since BlueSky credentials (username and password) are used directly from the user's device to interact with the BlueSky API, this security measure helps maintain a positive community experience while preventing the accidental or intentional posting of offensive or harmful content. The filter safeguards the integrity of the platform and protects users from exposure to inappropriate language.
        </p>
        <Link to="/" className="text-blue-500 hover:underline mt-4 block text-center">
          Back to App
        </Link>
      </div>
    </div>
  );
};

export default Privacy;
