import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom";
import Pricing from './Pricing';
import Success from './Success';
import Error from './Error';
import Privacy from './Privacy';
import Aiprompt from './Aiprompt';
import Termsconditions from './Termsconditions';
import { CreditsContext } from "./CreditsContext";
import logo from './logo192.png';
import './App.css';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { registerFirebaseServiceWorker } from "./firebaseServiceWorkerRegistration";


const MainPage = ({ credits, setCredits }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [userEmail, setUserEmail] = useState('');
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [cancelEmail, setCancelEmail] = useState("");
  const navigate = useNavigate();

  const firebaseConfig = {
  apiKey: "AIzaSyBU65QpO9Djb36kyQB_YbCfKHNmF1AWNrk",
  authDomain: "bluesky-notifications.firebaseapp.com",
  projectId: "bluesky-notifications",
  storageBucket: "bluesky-notifications.firebasestorage.app",
  messagingSenderId: "146950276453",
  appId: "1:146950276453:web:e8206b23ccddd008848d8c",
  };

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  useEffect(() => {
  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey: "BIqy2XuplcFwzzkkheoPqMimnnnKPF1sOAM5Ku7leVWpieV-X1PxtFsmgIk_-3rUcsB6mLvGAfNLdy5d-3bQd5Q",
        });
        console.log("🔹 FCM Token:", token);
      } catch (error) {
        console.error("🔥 Error getting FCM token:", error);
      }
    } else {
      console.warn("🚫 Notification permission denied.");
    }
  };

  registerFirebaseServiceWorker();
  requestNotificationPermission();
}, []);


  const planCredits = {
  basic: 10,
  pro: 50,
  };


  const getPlanNameFromAmount = (amount) => {
  if (amount === 1000) return "basic"; // €10.00/month
  if (amount === 4000) return "pro";   // €40.00/month
  return "basic"; // Default fallback
};


 const fetchSubscriptionStatus = async () => {
 setLoadingSubscription(true);
 console.clear();
 console.log("🔎 Checking subscription for email:", userEmail);

 if (!userEmail) {
   alert("Please enter an email address.");
   setLoadingSubscription(false);
   return;
 }

 try {
   const response = await fetch(`https://api.stripe.com/v1/customers?email=${userEmail}`, {
     headers: {
       Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
       "Content-Type": "application/x-www-form-urlencoded",
     },
   });

   const customerData = await response.json();
   console.log("✅ Customer Data:", customerData);

   if (!customerData.data?.length) {
     setSubscriptionData(null);
     setCredits(0);
     localStorage.setItem("blueskyCredits", 0);
     alert("No customer found for this email. Credits set to 0.");
     return;
   }

   const customerId = customerData.data[0].id;
   const subscriptionResponse = await fetch(`https://api.stripe.com/v1/subscriptions?customer=${customerId}`, {
     headers: {
       Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
       "Content-Type": "application/x-www-form-urlencoded",
     },
   });

   const subscriptionData = await subscriptionResponse.json();
   console.log("✅ Subscription Data:", subscriptionData);

   if (subscriptionData.data?.length) {
     const subscription = subscriptionData.data[0];
     setSubscriptionData(subscription);
     localStorage.setItem("subscription", JSON.stringify(subscription));

     const status = subscription.status;
     const amount = subscription.plan?.amount || 1000; // Default to basic plan amount if undefined
     const planName = getPlanNameFromAmount(amount);

     localStorage.setItem("blueskyPlan", planName); // Store plan in localStorage

     const billingDate = new Date(subscription.current_period_end * 1000).toLocaleDateString();
     const createdDate = new Date(subscription.created * 1000).toLocaleDateString();

     alert(`Subscription Found:\nPlan: ${planName}\nStatus: ${status}\nNext Billing: ${billingDate}\nCreated: ${createdDate}`);

     if (status === "active" || status === "trialing") {
       const creditsForPlan = planCredits[planName] || planCredits.basic;
       setCredits(creditsForPlan);
       localStorage.setItem("blueskyCredits", creditsForPlan);
       console.log(`🎉 ${creditsForPlan} credits granted for the ${planName} plan.`);
     } else {
       setCredits(0);
       localStorage.setItem("blueskyCredits", 0);
       console.log("🚫 No active subscription. Credits set to 0.");
     }
   } else {
     setSubscriptionData(null);
     setCredits(0);
     localStorage.setItem("blueskyCredits", 0);
     alert("No active subscription found. Credits set to 0.");
   }
 } catch (err) {
   console.error("❌ Network error:", err);
   alert(`Network Error: ${err.message}`);
 } finally {
   setLoadingSubscription(false);
 }
};

  const handleRedirect = () => {
    if (credits <= 0) {
      navigate("/pricing");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!username || !password || !prompt) {
      setError("All fields are required.");
      setResponse("");
      return;
    }

    try {
      console.log('Prompt: ', prompt)
      const res = await fetch("https://bsky-post-server-509ef1a1b88c.herokuapp.com/api/post", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password, prompt }),
      });

      const data = await res.json();

      if (res.ok) {
        setResponse(data.message);
        setError("");
        console.log("✅ Success:", data);

        if (credits > 0) {
          const newCredits = Math.max(credits - 1, 0);
          setCredits(newCredits);
          localStorage.setItem("blueskyCredits", newCredits);
        }
      } else {
        console.error("❌ Server Error:", data);
        setError(data.error || "Failed to fetch data from the server.");
        setResponse("");
      }
    } catch (err) {
      console.error("❌ Network/Fetch Error:", err);
      setError(`An error occurred: ${err.message}`);
      setResponse("");
    }
  };

  const cancelSubscription = async () => {
   if (!cancelEmail) {
     alert("Please enter the email used for the subscription.");
     return;
   }

   try {
     const response = await fetch(`https://api.stripe.com/v1/customers?email=${cancelEmail}`, {
       headers: {
         Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
         "Content-Type": "application/x-www-form-urlencoded",
       },
     });

     const customerData = await response.json();
     if (!customerData.data?.length) {
       alert("No customer found with this email.");
       return;
     }

     const customerId = customerData.data[0].id;
     const subscriptionResponse = await fetch(`https://api.stripe.com/v1/subscriptions?customer=${customerId}`, {
       headers: {
         Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
         "Content-Type": "application/x-www-form-urlencoded",
       },
     });

     const subscriptionData = await subscriptionResponse.json();
     if (!subscriptionData.data?.length) {
       alert("No active subscription found for this email.");
       return;
     }

     const subscriptionId = subscriptionData.data[0].id;
     const cancelResponse = await fetch(`https://api.stripe.com/v1/subscriptions/${subscriptionId}`, {
       method: "DELETE",
       headers: {
         Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
         "Content-Type": "application/x-www-form-urlencoded",
       },
     });

     const result = await cancelResponse.json();
     if (result.status === 'canceled') {
       alert("✅ Subscription successfully canceled. Credits reset to 0.");
       setSubscriptionData(null);
       setCredits(0);
       localStorage.setItem("blueskyCredits", 0);
       setShowCancelDialog(false);
       setCancelEmail("");
     } else {
       alert("❌ Failed to cancel subscription. Please try again.");
     }
   } catch (err) {
     alert(`Cancellation Error: ${err.message}`);
   }
 };



  return (
    <div className="App">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full mx-auto">
        <img src={logo} alt="BlueSky Logo" className="Static-logo mx-auto mb-6" />
        <h1 className="text-2xl font-bold text-center text-blue-600 mb-6">BlueSky A.I. Post App</h1>
        <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          <Link to="/privacy" className="underline text-gray-700 hover:text-gray-700">BlueSky Handle:</Link>
        </label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your BlueSky handle (no @ sign)"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            <Link to="/privacy" className="underline text-gray-700 hover:text-gray-700">Password:</Link>
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your BlueSky password"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
          <Link to="/aiprompt" className="underline text-gray-700 hover:text-gray-700">AI Prompt:</Link>
          </label>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none h-32"
            placeholder="Enter your A.I. post prompt (e.g. Write a fun post in English or any language you prefer, in less than 280 characters)"
          ></textarea>
        </div>
        {response && <p className="text-green-600 mt-4 text-center">{response}</p>}
        {error && <p className="text-red-600 mt-4 text-center">{error}</p>}

        <p className="text-gray-700 mb-4 text-center">
          {credits > 0 ? (
            <>Credits available: <span className="font-bold">{credits}</span></>
          ) : (
            "No credits remaining. Please view pricing plans."
          )}
        </p>

        <button
          onClick={handleRedirect}
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Post to BlueSky
        </button>

        <div className="subscription-check mt-6">
            <input
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Enter your email to check subscription"
              className="w-full border border-gray-300 rounded-lg px-3 py-2 mb-2"
            />
            <button
              onClick={fetchSubscriptionStatus}
              disabled={loadingSubscription}
              className="w-full bg-green-500 text-white font-semibold py-2 rounded-lg hover:bg-green-600 transition"
            >
              {loadingSubscription ? "Loading..." : "Check Subscription"}
            </button>

            <a
              href="mailto:info@jibodev.eu?subject=Cancellation%20Subscription&body=Please%20cancel%20my%20subscription."
              className="w-full inline-block text-center bg-red-500 text-white font-semibold py-2 rounded-lg hover:bg-red-600 transition mt-4"
            >
              Cancel Subscription
            </a>
            <footer className="footer">
          <div className="max-w-md mx-auto text-center mt-6 space-y-2">
            <Link to="/Termsconditions" className="text-blue-500 hover:underline">
              Terms & Conditions
            </Link>
            <br />
            <a
              href="mailto:info@jibodev.eu?subject=Contact Inquiry&body=Please write your message here."
              className="text-blue-500 hover:underline"
            >
              Contact Us
            </a>
          </div>
        </footer>
          </div>

        {subscriptionData && (
          <div className="subscription-info mt-6 p-4 bg-gray-100 rounded-lg shadow-inner">
            <h3 className="font-bold text-lg mb-2 text-green-600">✅ Subscription Details</h3>
            <p><strong>Subscription ID:</strong> {subscriptionData.id || "N/A"}</p>
            <p><strong>Status:</strong> {subscriptionData.status}</p>
            <p><strong>Billing Interval:</strong> {subscriptionData.plan?.interval || "N/A"}</p>
            <p><strong>Amount:</strong> €{(subscriptionData.plan?.amount || 0) / 100}</p>
            <p><strong>Next Billing Date:</strong> {new Date(subscriptionData.current_period_end * 1000).toLocaleDateString()}</p>
            <p><strong>Subscription Created:</strong> {new Date(subscriptionData.created * 1000).toLocaleDateString()}</p>
            <p><strong>Customer Email:</strong> {userEmail}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const App = () => {
  const [credits, setCredits] = useState(() => {
    const savedCredits = parseInt(localStorage.getItem("blueskyCredits"), 10);
    return !isNaN(savedCredits) && savedCredits > 0 ? savedCredits : 0;
  });

  return (
    <CreditsContext.Provider value={{ credits, setCredits }}>
      <Router basename="/bluesky">
        <Routes>
          <Route path="/" element={<MainPage credits={credits} setCredits={setCredits} />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/aiprompt" element={<Aiprompt />} />
          <Route path="/termsconditions" element={<Termsconditions />} />
        </Routes>

      </Router>
    </CreditsContext.Provider>
  );
};

export default App;
