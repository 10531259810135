import React, { useEffect, useState, useContext  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { CreditsContext } from "./CreditsContext"; // Import context

function Success() {
  const { setCredits } = useContext(CreditsContext); // Consume the context
  const [planDetails, setPlanDetails] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // Countdown for redirect

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const credits = queryParams.get('credits');
    if (credits) {
      const parsedCredits = credits === 'Infinity' ? Infinity : parseInt(credits, 10);
      localStorage.setItem('blueskyCredits', parsedCredits);
      setCredits(parsedCredits); // Ensure direct state update
      console.log("Credits set in Success.js:", parsedCredits);
    }
  }, [location, setCredits]);

  useEffect(() => {
    // Countdown for redirect
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      console.log('Redirecting to home page...');
      navigate('/');
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div className="success-container">
      <h2>Subscription Successful</h2>
      {planDetails ? (
        <div>
          <p>
            Thank you for subscribing to the <strong>{planDetails.plan.toUpperCase()}</strong> plan!
          </p>
          <p>
            {planDetails.credits === Infinity
              ? 'You now have unlimited credits for check-ins and check-outs.'
              : `You have received ${planDetails.credits} credits for check-ins and check-outs.`}
          </p>
        </div>
      ) : (
        <p>Processing your subscription...</p>
      )}
      <p>Redirecting to the home page in {countdown} seconds...</p>
    </div>
  );
}

export default Success;
